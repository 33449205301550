import React, { Component } from 'react';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
    };
  }

  createGallery = () => {
    const galleryLength = 180;
    let galleryObj = {
      id: 0,
      src: '',
    };
    const galleryArray = [];
    for (let i = 1; i <= galleryLength; i++) {
      galleryObj = {
        id: i,
        src: 'https://ipfs.ethosnft.com/legends/gallery2/' + i + '.jpg',
      };
      galleryArray.push(galleryObj);
    }
    this.setState({
      gallery: galleryArray,
    });
  }

  componentDidMount() {
    this.createGallery();
  }

  render() {
    const { gallery } = this.state;
    return (
      <div className="file-viewer__scroller">
        {gallery.length > 0
        && (
        <div className="file-viewer__gallery">
          {gallery.map((item, i) => (
            <img key={item.id} src={item.src} alt="" />
          ))}
        </div>
        )}
      </div>
    );
  }
}

export default Gallery;
