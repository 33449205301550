import '../../config/flow';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as fcl from '@onflow/fcl';

export default function FlowWallet(props) {
  const [user, setUser] = useState({ loggedIn: null });

  useEffect(() => fcl.currentUser.subscribe(setUser), []);

  const Login = (e) => {
    const { onLogInAttempt } = props;
    e.preventDefault();
    fcl.logIn();
    onLogInAttempt();
  };

  const GetUser = () => {
    const { onWalletConnection } = props;
    onWalletConnection(user);
  };

  const AuthedState = () => {
    GetUser();
    return (
      <div>
        <div>
          Address:
          {user?.addr ?? 'No Address'}
        </div>
        <button type="button" className="button-nav" onClick={fcl.unauthenticate}>Log Out</button>
      </div>
    );
  };

  const UnauthenticatedState = () => (
    <div>
      <a href="#" className="button" onClick={(e) => Login(e)}>Connect your flow wallet</a>
      {/* <button type="button" onClick={fcl.signUp}>Sign Up</button> */}
    </div>
  );

  return (
    <div>
      {user.loggedIn
        ? <AuthedState />
        : <UnauthenticatedState />}
    </div>
  );
}

FlowWallet.propTypes = {
  onWalletConnection: PropTypes.func,
  onLogInAttempt: PropTypes.func,
};
