import React, { Component } from 'react';
import FullPageLoader from '../throbber/loader';
import AccountManager from '../../managers/Account';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getParameterByName = (name, url) => {
    let newName = name;
    let newUrl = url;
    if (!url) newUrl = window.location.href;
    newName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + newName + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(newUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  componentDidMount = () => {
    // check for a nft token.
    let unauthPath = '/login';
    if (this.getParameterByName('nftToken', window.location.href)) {
      unauthPath = '/register';
      // protec against duplicate tokens
      Object.entries(localStorage).map((obj) => {
        const key = obj[0];
        if (key.includes('NFT_TRANSFER')) {
          sessionStorage.removeItem(key);
        }
        return true;
      });
      // force the one in the URL to be in memory
      sessionStorage.setItem('NFT_TRANSFER_' + this.getParameterByName('nftToken', window.location.href), this.getParameterByName('nftToken', window.location.href));
    }
    // check if the user is logged in. If they are, grab the account details
    if (AccountManager.isLoggedIn()) {
      window.location.pathname = '/account/collection';
    } else {
      window.location.pathname = unauthPath;
    }
  }

  render() {
    return (
      <div>
        <FullPageLoader />
      </div>
    );
  }
}

export default Auth;
