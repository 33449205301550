import React, { Component } from 'react';
import FlowWallet from './flowWallet';
import Account from '../../managers/Account';

class AccountWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingIn: false,
    };
  }

  updateWallet = async (user) => {
    const { isLoggingIn } = this.state;
    const payload = {
      blockchain: 'flow',
      walletType: 'blocto',
      walletAddress: null,
    };
    if (isLoggingIn) {
      payload.walletAddress = user.addr;
      console.log('User just connected a wallet');
      console.log(payload);
      await Account.addWallet(payload, Account.getToken());
      // TODO: Save the wallet address to our database
    }
  }

  onLogInAttempt = () => {
    console.log('User is attempting to connect a wallet');
    this.setState({
      isLoggingIn: true,
    });
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="account-box -account-section">
          <h2 className="account-heading__h2">
            Wallet Settings
          </h2>
          <div className="account-subheading">
            <p>
              We recommend that you connect your own wallet. Once your wallet is connected you can transfer / claim any NFT that ethos may be holding for you so that you have full and complete ownership of your NFTs. Some of the benefits of having full ownership over your NFTs are highlighted&nbsp;
              
              <a href="https://www.ethosnft.com/blog" target="_blank" rel="noreferrer">here</a>
              .
            </p>
            <p>
              {'If you don\'t want to connect your own wallet right now, that\'s ok! We are happy to hold any NFTs for you to make the process easier and we will always enable you to connect your own wallet in the future.'}
            </p>
          </div>
          <div>
            <FlowWallet
              onWalletConnection={(user) => this.updateWallet(user)}
              onLogInAttempt={() => this.onLogInAttempt()} />
          </div>
        </div>
      </div>
    );
  }
}

export default AccountWallet;
