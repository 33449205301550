import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import SimpleModal from '../modal/modal';
import LoginForm from '../account/loginForm';
// import AccountDropdown from '../common/AccountDropdown';
// import Dropdown from '../common/Dropdown';

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      username: '',
      showModal: false,
      showMobileNav: false,
      isCountdown: false,
      currentDate: moment().unix() * 1000,
    };
    PubSub.subscribe('account_updated', this.onPubSubEvent);
  }

  onPubSubEvent = () => {
    this.componentDidMount();
  }

  logout = () => {
    Account.logout();
  }

  getAccountDetails = async () => {
    const accountData = await Account.get(Account.getToken());
    if (accountData) {
      this.setState({
        username: accountData.username,
      });
    }
  }

  async componentDidMount() {
    const { dropDetails } = this.props;
    const { currentDate } = this.state;
    let countdownDate;
    // Check if dropData.drop has a countdown set. If it doesn, always display the countdown page
    if (!process.env.E_DROP_ONLY) {
      if (dropDetails.drop.countDown && dropDetails.drop.countDown.countDownDate) {
        countdownDate = moment(dropDetails.drop.countDown.countDownDate).unix() * 1000;
      }
      if (countdownDate > currentDate) {
        this.setState({
          isCountdown: true,
        });
      }
    }
    if (Account.isLoggedIn()) {
      await this.getAccountDetails();
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }

    const topnav = document.getElementById('topNav');
    document.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        topnav.classList.add('-animate');
      } else {
        topnav.classList.remove('-animate');
      }
    });
  }

  loginSuccess = async () => {
    this.setState({
      showModal: false,
      isLoggedIn: true,
    });
    PubSub.publish('account_updated');
    // if (window.location.pathname.includes('register')) {
    //   window.location.pathname = '/drop/' + process.env.DROP_ID;
    // }
    if (process.env.E_DROP_ONLY === 'true') {
      window.location.href = '/account/collection';
    } else {
      window.location.href = '/';
    }
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  openLoginModal = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
      showMobileNav: false,
    });
  }

  toggleNav = (e) => {
    e.preventDefault();
    const { showMobileNav } = this.state;
    this.setState({
      showMobileNav: !showMobileNav,
    });
  }

  handleMobileNav = () => {
    this.setState({
      showMobileNav: false,
    });
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const {
      isCountdown, isLoggedIn, showModal, showMobileNav, username,
    } = this.state;
    return (
      <div id="topNav" className="top-nav">
        <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          <LoginForm
            onSuccess={() => this.loginSuccess()}
            onFail={(error) => this.loginFailed(error)}
            onRegisterButton={() => this.closeModal()} />
        </SimpleModal>
        {/* <div className="top-nav__vertical">

        </div> */}
        <div className="top-nav__inner">
          {isCountdown
            ? <Link to="/countdown"><img className="top-nav__logo" src={process.env.NAV_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} /></Link>
            : <Link to="/"><img className="top-nav__logo" src={process.env.NAV_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} /></Link>}
          {isLoggedIn
            ? (
              <div className={showMobileNav ? 'top-nav__ctas -show-mobile' : 'top-nav__ctas'}>
                <b>
                  {username
                && <Link className="button-nav -transparant" to="/account/details" onClick={(e) => this.handleMobileNav(e)}>{username || ' '}</Link>}
                </b>
                {/* <AccountDropdown /> */}
                <Link
                  className="button-nav button-nav-dropdown"
                  to="/account/collection"
                  onClick={(e) => this.handleMobileNav(e)}>
                  My Account
                </Link>
                <a
                  href="#"
                  className="button-nav"
                  onClick={(e) => this.logout(e)}>
                  Log Out
                </a>
              </div>
            )
            : (
              <div className={showMobileNav ? 'top-nav__ctas -show-mobile' : 'top-nav__ctas'}>
                <a
                  href="#"
                  className="button-nav"
                  onClick={(e) => this.openLoginModal(e)}>
                  Log In
                </a>
                <Link
                  className="button-nav"
                  to="/register"
                  onClick={(e) => this.handleMobileNav(e)}>
                  Register
                </Link>
              </div>
            )}
          <a
            href="#"
            className={showMobileNav ? 'mobile-nav-icon open' : 'mobile-nav-icon'}
            onClick={(e) => this.toggleNav(e)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
    );
  }
}

TopNav.propTypes = {
  dropDetails: PropTypes.objectOf(PropTypes.object()),
};

export default TopNav;
