import React, { useState, useEffect } from 'react';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
// import IconButton from '@mui/material/IconButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
// import {NftCard} from '../nft/NftCard';
import { toast } from 'react-toastify';
import TransferNFT from './transferNFT';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import NftCard from '../nft/NftCard';
import SimpleModal from '../modal/modal';
import Gallery from './gallery';

function AccountCollection() {
  const [isLoading, setIsLoading] = useState(true);
  const [collection, setCollection] = useState([]);
  const [transfer, setTransferModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isModalLoading, setModalLoadingState] = useState(false);
  const [showFileViewer, setFileViewerState] = useState(false);
  const [updatedNft, setUpdatedNft] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [tokenSupply, setTokenSupply] = useState(0);
  
  const closeFileViewer = () => {
    setFileViewerState(false);
    setSelectedFile(null);
  };

  const setTransferState = (nft) => {
    setTransferModal(nft);
    setUpdatedNft(nft);
    console.log(nft);
  };

  const showViewer = (viewerData) => {
    setSelectedFile(viewerData);
    setFileViewerState(true);
  };

  const updateCollection = async () => {
    // updates the collection in memory
    console.log(updatedNft);
    // add the newItem to the collection and replace the old one
    console.log(collection);
  };

  const confirmCancel = async () => {
    updatedNft.hasPendingTransfer = false;
    setModalLoadingState(true);

    const payload = {
      nftId: updatedNft.nftId,
      creatorId: process.env.CREATOR_ID,
    };

    const cancelled = await AccountManager.cancelTransfer(AccountManager.getToken(), payload);

    if (cancelled && cancelled.success) {
      toast.success('Successfully cancelled transfer.');
    } else {
      toast.error('Oops. Something went wrong. Please try again later.');
    }
    setModalLoadingState(true);
    setShowModal(false);
    updateCollection();
  };

  const confirmTransfer = () => {
    // alert(newItem);
    updatedNft.hasPendingTransfer = true;
    updateCollection();
    setTransferModal(false);
  };

  const cancelTransfer = (newItem) => {
    setUpdatedNft(newItem);
    setShowModal(true);
  };

  const getCollection = async () => {
    // get the collection from the server
    setIsLoading(true);
    setTransferModal(false);
    AccountManager.getCollection(AccountManager.getToken()).then((res) => {
      setCollection(res);
      setHasError(false);
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      setHasError(true);
      setCollection([]);
      toast.error('Oops. There was a problem getting your collection data. Please try again later.');
    });
  };

  useEffect(() => {
    getCollection();
  }, []);

  return (
    <div>
      {showFileViewer
        && (
        <div className="file-viewer" onClick={() => closeFileViewer()}>
          <div className="file-viewer__inner">
            <a className="modal__close -outside-right" href="#" onClick={() => closeFileViewer()}>
              <img src="https://ipfs.ethosnft.com/global/icon-close-dark.svg" alt="Close modal" />
            </a>

            {selectedFile && selectedFile.asset
            && (
              <Gallery />
            // <p className="file-viewing__not-supported">
            //   {'Your device doesn\'t have a PDF plugin. '}
            //   <a href={selectedFile.asset.mediaUrl}>
            //     click here to download the PDF file.
            //   </a>
            // </p>
            // <iframe
            //   className="file-viewing__embed"
            //   src={selectedFile.asset.mediaUrl + '#view=FitH&pagemode=none'}
            //     width="100%"
            //     height="100%"
            //     title="Account Collection Embed">
            //   {/* <p className="file-viewing__not-supported">
            //     {'Your device doesn\'t have a PDF plugin. '}
            //     <a href={selectedFile.asset.mediaUrl}>
            //       click here to download the PDF file.
            //     </a>
            //   </p> */}
            // </iframe>
            )}
          </div>
        </div>
        )}
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isModalLoading
          && <Throbber throbberText="Cancelling transfer! Please stand by..." />}
        Are you sure you want to cancel this transfer?
        <div className="form-cta">
          <button
            className="button button-small button-confirm -no-margin"
            type="submit"
            onClick={(e) => confirmCancel(e)}>
            Confirm Cancel
          </button>
          &nbsp;
          <button
            className="button button-small -no-margin"
            type="submit"
            onClick={() => setShowModal(false)}>
            Back
          </button>
        </div>
      </SimpleModal>

      {transfer
      && (
      <div>
        <TransferNFT nft={transfer} onClose={() => setTransferModal(false)} onSuccess={() => confirmTransfer()} />
      </div>
      )}
      <div className="account-box -account-section -no-right-padding">
        {isLoading
        && <Throbber throbberText="Loading collection! Please stand by..." />}
        <h2 className="account-heading__h2">
          Account Collection
        </h2>
        <div className="account-subheading">
          View your ethos purchases here
        </div>

        {!hasError
          ? (
            <div className="collection-container__outer">
              { collection.length > 0 ? (
                collection.map((item) => (
                  <div key={item.dropId}>
                    <div className="collection-drop__header">{item.name}</div>
                    <div className="collection-container">
                      { item.nfts.length > 0 ? (
                        item.nfts.map((nft) => (
                          <NftCard key={nft.nftId} item={nft} onViewerSelect={() => showViewer(nft)} onTransfer={() => setTransferState(nft)} onCancel={(cancelledTransfer) => cancelTransfer(cancelledTransfer)} />
                        ))
                      ) : (
                        <div>No NFTs for this collection.</div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-content -margin-right">
                  {'You haven\'t purchased any NFTs yet. Once you have purchased an NFT from the ethos multiverse, it will show up here.'}
                </div>
              )}
            </div>
          )
          : (
            <div className="collection-container__outer">
              <div className="no-content -margin-right">
                {'We are having trouble retrieving your collection. This is not your fault. We\'re working on restoring functionality ASAP. Please try again later.'}
              </div>
            </div>
          )}

        {/* <div className="collection-container">
          { collection.length > 0 ? (
            collection.map((item) => (
              <NftCard key={item.nftId} item={item} />
            ))
          ) : (
            <div>{'You haven\'t purchased any NFTs yet.'}</div>
          )}
        </div> */}
      </div>
    </div>
  );
}
export default AccountCollection;
